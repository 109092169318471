import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["withdrawalsRow", "membersRow", 
    "membersTitle", "withdrawalsTitle", "loader",
    "withdrawalsContent", "membersContent",
    "membersCount",'chartContainer', 'chartLoader']

    connect() {
        this.membersClick()
        this.initChart()
        this.fetchChartData()
    }

    lockStaffClick(event) {
        event.preventDefault()
        var that = this

        swal("提示", "【锁定】该代理商后，将不再为该代理商进行收益结算。\n锁定后，您还可以选择【解锁】代理商。", {
                buttons: {
                    cancel: '取消',
                    defeat: '锁定'
                }
            }
        ).then((value) => {
            if (value == 'defeat') {
                that.processLockStatus(1)
            }
        });

    }

    unlockStaffClick(event) {
        event.preventDefault()
        var that = this

        swal("提示", "【解锁】后，将恢复其收益结算。", {
                buttons: {
                    cancel: '取消',
                    defeat: '解锁'
                }
            }
        ).then((value) => {
            if (value == 'defeat') {
                that.processLockStatus(0)
            }
        });

    }

    processLockStatus(status) {
        var url = '/admin/agencies/agency_lock'
        var staffId = this.data.get("staffId")
        var urlData = {
            staff_id: staffId,
            status: status
        }

        $.ajax({
            method: "GET",
            url: url,
            data: urlData
        })
        .done(function(res) {
            console.log(res)
            window.location.href = '/admin/agencies/' + staffId;
        })

    }

    membersClick(event) {
        $(this.membersContentTarget).show()
        $(this.withdrawalsContentTarget).hide()

        var url = '/admin/agencies/members'
        var staffId = this.data.get("staffId")
        var urlData = {
            staff_id: staffId,
        }
        const that = this
        $(this.loaderTarget).show()

        $.ajax({
            method: "GET",
            url: url,
            data: urlData
        })
        .done(function(res) {
            // console.log(res)
            var str = ""
            var sCount = 0
            var list = res['list']
            for (var i=0,len=list.length; i<len; i++) {
                var m = list[i]
                str += "<tr><td><a href='/admin/users/" + m['user_id'] + "' >" + m['email'] + 
                "</a></td><td>" + m['qq_nickname'] +
                "</td><td>" + m['phone'] + 
                "</td><td>" + m['created_at'] + 
                "</td><td>" + m['fee_date'] + 
                "</td><td><div class='badge " + that.membersStatusClass(m)['statusClass'] + "' >" + m['status_str'] + 
                "</div></td><td>¥ " + m['last_revenue'] + 
                "</td><td>" + that.membersStatusClass(m)['actionBtns'] +
                "</td></tr>"

                if (m['status'] == 0) {
                    sCount++;
                }
            }
            that.membersRowTarget.innerHTML = str

            that.membersCountTarget.innerHTML = list.length

            if (sCount > 0) {
                that.membersTitleTarget.innerHTML = "成员管理（待审核" + sCount + "）" 
            } else {
                that.membersTitleTarget.innerHTML = "成员管理"
            }

            if (res['withdrawal_wait_count'] > 0) {
                that.withdrawalsTitleTarget.innerHTML = "提现记录（待审核" + res['withdrawal_wait_count'] + "）" 
            } else {
                that.withdrawalsTitleTarget.innerHTML = "提现记录"
            }

            $(that.loaderTarget).hide()
        })
    }

    withdrawalsClick(event) {
        $(this.membersContentTarget).hide()
        $(this.withdrawalsContentTarget).show()

        var url = '/admin/agencies/withdrawals'
        var staffId = this.data.get("staffId")
        var urlData = {
            staff_id: staffId,
        }
        const that = this
        $(this.loaderTarget).show()

        $.ajax({
            method: "GET",
            url: url,
            data: urlData
        })
        .done(function(res) {
            // console.log(res)
            var str = ""
            var sCount = 0
            var list = res['list']
            for (var i=0,len=list.length; i<len; i++) {
                var m = list[i]
                str += "<tr><td>" + m['created_at'] + 
                "</td><td>" + m['start_date'] +
                "</td><td>" + m['end_date'] + 
                "</td><td>" + (m['bank_type'] == 'personal' ? '个人' : '公司') + 
                "</td><td>¥ " + m['apply_amount'] + 
                "</td><td>¥ " + m['final_amount'] + 
                "</td><td><div class='badge " + that.withdrawalsStatusClass(m)['statusClass'] + "' >" + m['status_str'] + 
                "</div></td><td>" + (m['remark'] ? m['remark'] : '') + 
                "</td><td>" + that.withdrawalsStatusClass(m)['actionBtns'] +
                "</td></tr>"

                if (m['status'] == 0) {
                    sCount++;
                }
            }

            that.withdrawalsRowTarget.innerHTML = str
            if (sCount > 0) {
                that.withdrawalsTitleTarget.innerHTML = "提现记录（待审核" + sCount + "）" 
            } else {
                that.withdrawalsTitleTarget.innerHTML = "提现记录"
            }

            $(that.loaderTarget).hide()

        })
    }

    membersStatusClass(value) {
        var classValue = 'badge-primary'
        var actionBtns = ''
        var aBtn = "<a href='#' class='btn btn-success mr-1' data-action='staff-mgr#memberCheckClick' data-user-id='" + value['user_id'] + "'><i class='fas fa-check'></i></a>" //<a href='<%= admin_staff_path(staff.id) %>' class='btn btn-primary'>详情</a>
        var bBtn = "<a href='#' class='btn btn-danger' data-action='staff-mgr#memberUncheckClick' data-user-id='" + value['user_id'] + "'><i class='fas fa-times'></i></a>"
        switch(value['status'])
        {
            case 0:
                classValue = 'badge-primary'
                actionBtns += aBtn + bBtn
                break;
            case 1:
                classValue = 'badge-success'
                actionBtns += bBtn
                break;
            case 2:
                classValue = 'badge-light'
                actionBtns = aBtn
                break;
        }

        return {
            statusClass: classValue,
            actionBtns: actionBtns
        }
    }

    memberCheckClick(event) {
        event.preventDefault()
        var that = this
        var userId = event.currentTarget.dataset.userId

        swal("提示", "【审核有效】后，该成员收益将计入代理商收益。", {
                buttons: {
                    cancel: '取消',
                    defeat: '审核有效'
                }
            }
        ).then((value) => {
            if (value == 'defeat') {
                that.processMemberStatus(1, userId)
            }
        });

    }

    memberUncheckClick(event) {
        event.preventDefault()
        var that = this
        var userId = event.currentTarget.dataset.userId

        swal("提示", "【审核无效】后，该成员收益将不再计入代理商收益。", {
                buttons: {
                    cancel: '取消',
                    defeat: '审核无效'
                }
            }
        ).then((value) => {
            if (value == 'defeat') {
                that.processMemberStatus(2, userId)
            }
        });

    }

    processMemberStatus(status, userId) {
        var url = '/admin/agencies/member_check'
        var urlData = {
            user_id: userId,
            status: status
        }
        const that = this
        $(this.loaderTarget).show()

        $.ajax({
            method: "GET",
            url: url,
            data: urlData
        })
        .done(function(res) {
            // console.log(res)
            that.membersClick()
        })
    }

    withdrawalsStatusClass(value) {
        var classValue = 'badge-primary'
        var actionBtns = ''
        var aBtn = "<a href='#' class='btn btn-primary mr-1' data-action='staff-mgr#payClick' data-withdrawal-id='" + value['id'] + "'>系统打款</a>"
        var bBtn = "<a href='#' class='btn btn-danger' data-action='staff-mgr#rejectClick' data-withdrawal-id='" + value['id'] + "'>驳回</a>"
        var cBtn = "<a href='#' class='btn btn-warning mr-1' data-action='staff-mgr#companyClick' data-withdrawal-id='" + value['id'] + "'>详情</a>"
        switch(value['status'])
        {
            case 0:
                classValue = 'badge-primary'
                if (value['bank_type'] == 'personal') {
                    actionBtns += aBtn + bBtn
                } else if (value['bank_type'] == 'company') {
                    actionBtns += cBtn + bBtn
                }
                
                break;
            case 1:
                classValue = 'badge-warning'
                break;
            case 2:
                classValue = 'badge-danger'
                break;
            case 9:
                classValue = 'badge-success'
                break;
        }

        return {
            statusClass: classValue,
            actionBtns: actionBtns
        }
    }

    initChart() {
        var chartData = this.createChartData()
        this.chart = new Chart(this.chartContainerTarget, {
            type: "line",
            data: chartData,
            options: {
            "scales": {
                "yAxes": [{
                "ticks": {
                    "beginAtZero": true
                }
                }]
            }
            },
            plugins: {},
        })
    }

    createChartData(payload = { labels: [], data: [] }) {
        return {
            labels: payload.labels,
            datasets: payload.datasets
        }
    }

    fetchChartData() {
        $(this.chartContainerTarget).hide()
        $(this.chartLoaderTarget).show()

        var url = '/admin/agencies/chart_data'
        var staffId = this.data.get("staffId")
        var urlData = {
            staff_id: staffId,
        }
        const that = this

        $.ajax({
            method: "GET",
            url: url,
            data: urlData
        })
        .done(function(res) {
            // console.log(res)
            that.showChart(res)
        })
    }
    
    showChart(payload) {
        $(this.chartLoaderTarget).hide()
        var chartData = this.createChartData(payload)
        this.chart.data = chartData
        this.chart.update()
        $(this.chartContainerTarget).show()
    }

    rejectClick(event) {
        event.preventDefault()
        var that = this
        var id = event.currentTarget.dataset.withdrawalId
        var url = '/admin/agencies/withdrawal_reject'
        var urlData = {
            withdrawal_id: id,
        }

        swal("提示", "【驳回】提现申请后，代理商须重新发起提现请求。", {
                buttons: {
                    cancel: '取消',
                    defeat: '驳回申请'
                }
            }
        ).then((value) => {
            if (value == 'defeat') {
                $(that.loaderTarget).show()

                $.ajax({
                    method: "GET",
                    url: url,
                    data: urlData
                })
                .done(function(res) {
                    // console.log(res)
                    that.withdrawalsClick()
                })
            }
        });    
    }

    payClick(event) {
        event.preventDefault()
        var that = this
        var id = event.currentTarget.dataset.withdrawalId
        var url = '/admin/agencies/withdrawal_agree'
        var urlData = {
            withdrawal_id: id,
        }

        swal("提示", "【确认打款】后，系统将自动打款。\n如果打款失败，代理商须重新发起提现请求。", {
                buttons: {
                    cancel: '取消',
                    defeat: '确认打款'
                }
            }
        ).then((value) => {
            if (value == 'defeat') {
                $(that.loaderTarget).show()

                $.ajax({
                    method: "GET",
                    url: url,
                    data: urlData
                })
                .done(function(res) {
                    // console.log(res)
                    if (res.code != 0) {
                        alert(res.msg)
                    }
                    that.withdrawalsClick()
                })
            }
        });

    }

    companyClick(event) {
        event.preventDefault()
        var that = this
        var id = event.currentTarget.dataset.withdrawalId
        var url = '/admin/agencies/withdrawal_details'
        var urlData = {
            withdrawal_id: id,
        }

        var agreeUrl = '/admin/agencies/withdrawal_agree_company'

        // 获取详情打款信息
        $.ajax({
            method: "GET",
            url: url,
            data: urlData
        })
        .done(function(res) {
            // console.log(res)
            var payInfo = "【打款账户类型】企业" + 
                "\n【开户行】" + res.bank_name +
                "\n【银行账户】" + res.bank_account_name +
                "\n【银行账号】" + res.bank_card +
                "\n【打款金额】¥ " + res.final_amount + 
                "\n【手机号】" + res.phone + 
                "\n【状态】" + res.status +
                "\n\n" + 
                "\n【==注意：手动完成打款后，请点击‘确认已完成手动打款’按钮，用于变更状态。未打款请不要点击，过程不可逆，请谨慎操作。】"

            swal("企业手动打款", payInfo, {
                buttons: {
                    cancel: '取消',
                    defeat: '确认已完成手动打款'
                }
            }
            ).then((value) => {
                if (value == 'defeat') {
                    $(that.loaderTarget).show()

                    $.ajax({
                        method: "GET",
                        url: agreeUrl,
                        data: urlData
                    })
                    .done(function(res) {
                        // console.log(res)
                        if (res.code != 0) {
                            alert(res.msg)
                        }
                        that.withdrawalsClick()
                    })
                }
            });
        })



        

        


        
    }

}
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dateRange", "chartContainer", "loader", "sourceType"]

  connect() {
    this.initChart()
    this.fetchChartDataForRange()
  }

  initChart() {
    var chartData = this.createChartData()
    this.chart = new Chart(this.chartContainerTarget, {
      type: "line",
      data: chartData,
      options: {
        "scales": {
          "yAxes": [{
            "ticks": {
              "beginAtZero": true
            }
          }]
        }
      },
      plugins: {},
    })
  }

  createChartData(payload = { labels: [], data: [] }) {
    return {
        labels: payload.labels,
        datasets: payload.datasets
    }
  }

  rangeSelected(event) {
    event.preventDefault()

    const selectedDateRange = event.currentTarget.dataset.dateRange
    this.dateRangeTargets.forEach((el) => {
      let elDateRange = el.dataset.dateRange
      el.classList.toggle("active", elDateRange == selectedDateRange)
    })
    this.fetchChartDataForRange()
  }

  fetchChartDataForRange() {
    var range = ""
    this.dateRangeTargets.forEach((el) => {
      if (el.classList.length == 2) {
        range = el.dataset.dateRange
      }
    })

    var source = ""
    if (this.hasSourceTypeTarget) {
      source = this.sourceTypeTarget.dataset.current
    }

    $(this.chartContainerTarget).hide()
    $(this.loaderTarget).show()
    const dataUrl = this.data.get("dataUrl")
    const that = this
    $.ajax({
      method: "GET",
      url: dataUrl,
      data: {
        date_range: range,
        data_source: source
      }
    })
    .done(function(payload) {
      that.showChart(payload)
    })
  }

  showChart(payload) {
    $(this.loaderTarget).hide()
    var chartData = this.createChartData(payload)
    this.chart.data = chartData
    this.chart.update()
    $(this.chartContainerTarget).show()
  }

  sourceTypeSelected(event) {
    event.preventDefault()
    this.sourceTypeTarget.innerHTML = event.currentTarget.innerHTML;
    var current = event.currentTarget.dataset.source;
    this.sourceTypeTarget.dataset.current = current;

    this.fetchChartDataForRange()
  }

}

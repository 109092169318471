import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "agree", "reject", "loader", "rejectRemark"]

    connect() {
        $(this.loaderTarget).hide()
    }

    agreeClick(event) {
        event.preventDefault()
        this.processStaffApplyByAction(1)
    }

    rejectClick(event) {
        event.preventDefault()
        this.processStaffApplyByAction(2)
    }

    processStaffApplyByAction(action) {
        var url = ''
        var urlData = {
            apply_id: this.data.get("applyId")
        }
        if (action == 1) {
            url = '/admin/agencies/apply_agree'
        } else if (action == 2) {
            url = '/admin/agencies/apply_reject'
            urlData['reject_remark'] = this.rejectRemarkTarget.value
            // console.log(urlData)
        } else {
            return
        }

        $(this.agreeTarget).hide()
        $(this.rejectTarget).hide()
        $(this.loaderTarget).show()
        const that = this

        $.ajax({
            method: "GET",
            url: url,
            data: urlData
        })
        .done(function(res) {
            console.log(res)
            window.location.href = '/admin/agencies/apply';
        })

    }

}
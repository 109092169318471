import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dateRange", "fallTab", "referentDate" ]

  rangeSelected(event) {
    event.preventDefault()

    const selectedDateRange = event.currentTarget.dataset.dateRange
    this.dateRangeTargets.forEach((el) => {
      let elDateRange = el.dataset.dateRange
      el.classList.toggle("active", elDateRange == selectedDateRange)
    })
    this.fetchFallListForRange()
  }

  fetchFallListForRange() {
    var range = ""
    this.dateRangeTargets.forEach((el) => {
      if (el.classList.length == 2) {
        range = el.dataset.dateRange
      }
    })

    const dataUrl = this.data.get("dataUrl")
    const that = this
    $.ajax({
      method: "GET",
      url: dataUrl,
      data: {
        date_range: range,
      }
    })
    .done(function(payload) {
      that.showTab(payload)
    })
  }

  createTbody(payload) {
    let tbody = ""
    payload.forEach((line) => {
      let [user_id, email, accountName, qq, isp, referentRevenue, yesterdayRevenue, diff, rate] = line
      tbody +=
        `<tr> 
           <td> <a href="/admin/users/${user_id}">${email}</a> </td> 
           <td> ${accountName == null ? "" : accountName} </td> 
           <td> ${qq} </td> 
           <td> ${isp} </td>
           <td> ${referentRevenue} </td> 
           <td> ${yesterdayRevenue} </td> 
           <td> ${diff} </td>
           <td> ${rate} </td>
        </tr>`
    })
    return tbody
  }

  showTab(payload) {
    var tbody = this.createTbody(payload['fall'])
    this.fallTabTarget.innerHTML = tbody
    this.referentDateTarget.innerHTML = payload['referent_date']
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "rangeSelected", "statusSelected", "loader", "dataRow"]

    connect() {
        this.fetchNodeStatsData();
    }

    rangeSelected(event) {
        event.preventDefault()
        this.rangeSelectedTarget.innerHTML = event.currentTarget.innerHTML;
        var current = event.currentTarget.dataset.range;
        this.rangeSelectedTarget.dataset.current = current;

        this.fetchNodeStatsData();
    }

    statusSelected(event) {
        event.preventDefault()
        this.statusSelectedTarget.innerHTML = event.currentTarget.innerHTML;
        var current = event.currentTarget.dataset.status;
        this.statusSelectedTarget.dataset.current = current;

        this.fetchNodeStatsData();
    }

    fetchNodeStatsData() {
        var range = this.rangeSelectedTarget.dataset.current;
        var status = this.statusSelectedTarget.dataset.current;

        $(this.loaderTarget).show()
        const nodeStatUrl = this.data.get("url")
        const that = this
        $.ajax({
            method: "GET",
            url: nodeStatUrl,
            data: {
                range: range,
                status: status
            }
        }).done(function(result) {
            $(that.loaderTarget).hide()
            that.dataRowTarget.innerHTML = result
        })
    }
}
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "demand", "description", "detail", "networkType", "networkDetail",
        "dialLinkInfo", "dailLinkInfo", "dedicatedLineInfo",
        "dedicatedLineTabBody", "dailLinkTab", "dailLinkTabBody", "nodeId",
        "province", "diskCapacity", "ramSize", "cpuCores", "connectivityHint",
        "submit", "connectivityBtn", "dailHint", "dedicatedLineInfoHint", "submitHint", 
        "parseDialLinkInfoBtn", "parseDedicatedLineInfoBtn", "inputDemand", "isp",
        "routerUrl", "routerAccount", "routerPassword", "nicTabBody", "userId"]

    showDemandDetail() {
        let demand = this.demandTarget.value;
        this.descriptionTargets.forEach((el) => {
            let elDemand = el.dataset.demand
            el.classList.toggle("hidden", elDemand !== demand)
        })

        this.detailTargets.forEach((el) => {
            let detailDemand = el.dataset.demand
            el.classList.toggle("hidden", !detailDemand.includes(demand))
        })

        if(!['base', 'MN', "BI"].includes(demand)) {
            this.submitHintTarget.innerHTML = "请先进行联通性测试，拨号网络完成拨号测试，汇聚网络填写路由信息"
            this.submitTarget.disabled = true
        } else {
            this.submitHintTarget.innerHTML = ""
            this.submitTarget.disabled = false
        }
    }

    showNetworkInfoInput() {
        let networkTypeSelect = this.networkTypeTarget.value
        this.networkDetailTargets.forEach((el) => {
            let networkType = el.dataset.networkType
            let networkTypeArr = networkType.split("/")
            el.classList.toggle("hidden", !networkTypeArr.includes(networkTypeSelect))
        })
        let without_dial = ["专线", "路由拨号汇聚"]
        if (without_dial.includes(networkTypeSelect)) {
            this.data.set('link_dial_done', true)
        } else if (networkTypeSelect === "路由专线汇聚") {
            this.data.set('link_dial_done', ! this.emptyRouterInfo())
        } else {
            this.data.set('link_dial_done', false)
        }
        this.canSubmit()
    }

    canSubmit() {
        if ((this.data.get('connectivity') === "true") && (this.data.get('link_dial_done') === "true")) {
            this.submitTarget.disabled = false
            this.submitHintTarget.innerHTML = ""
        } else {
            this.submitTarget.disabled = true
            this.submitHintTarget.innerHTML = "请先进行联通性测试，拨号网络完成拨号测试，汇聚网络填写路由信息"
        }
    }

    parseDialLinkInfo(event) {
        event.preventDefault()
        const that = this
        const dialLinkInfo = this.dailLinkInfoTarget.value
        const node_id = this.nodeIdTarget.value
        const th = "<tr> <th>vlanId</th> <th>宽带账号</th> <th>宽带密码</th> <th>拨号网卡</th> <th>拨号带宽(Mbps)</th> <th>状态</th> </tr>"
        let td = ""
        this.parseDialLinkInfoBtnTarget.classList.toggle("btn-progress")

        const dialUrl = this.data.get("dialUrl")
        $.ajax({
          method: "POST",
          url: dialUrl,
          data: {
            node_id: node_id,
            network_type: 2,
            link_info: dialLinkInfo
          }
        })
        .done(function(payload) {
            if($.isEmptyObject(payload)) {
                that.data.set('link_dial_done', false)
                that.dailHintTarget.innerHTML = "连接失败，请检查 client id"
            } else {
                let all_link_ready = true
                payload.forEach((line) => {
                    td += `<tr><td>${line.vlan_id}</td><td>${line.account}</td><td>${line.password}</td><td>${line.nic}</td><td>${line.bandwidth}</td><td>${line.status_name}</td></tr>`
                    all_link_ready = (all_link_ready && line.status == 1)
                })
                that.dailLinkTabBodyTarget.innerHTML = th + td
                that.data.set('link_dial_done', all_link_ready)
            }
            that.parseDialLinkInfoBtnTarget.classList.toggle("btn-progress")
            that.canSubmit()
        })
    }

    parseDedicatedLineInfo(event) {
        event.preventDefault()
        const that = this
        const dedicatedLineInfo = this.dedicatedLineInfoTarget.value
        const node_id = this.nodeIdTarget.value
        const th = "<tr> <th>IP</th> <th>子网掩码</th> <th>网关地址</th> <th>vlan id</th> <th>网卡</th> <th>拨号带宽(Mbps)</th> <th>状态</th> </tr>"
        let td = "";
        this.parseDedicatedLineInfoBtnTarget.classList.toggle("btn-progress")

        const dialUrl = this.data.get("dialUrl")
        $.ajax({
            method: "POST",
            url: dialUrl,
            data: {
                node_id: node_id,
                network_type: 4,
                link_info: dedicatedLineInfo
            }
        })
        .done(function(payload) {
            if($.isEmptyObject(payload)) {
                that.data.set('link_dial_done', false)
                that.dedicatedLineInfoHintTarget.innerHTML = "连接失败，请检查 client id"
            } else {
                let all_link_ready = true
                payload.forEach((line) => {
                    td += `<tr><td>${line.ip}</td><td>${line.mask}</td><td>${line.gateway}</td><td>${line.vlan_id}</td><td>${line.nic}</td><td>${line.bandwidth}</td><td>${line.status_name}</td></tr>`
                    all_link_ready = (all_link_ready && line.status == 1)
                })
                that.dedicatedLineTabBodyTarget.innerHTML = th + td
                that.data.set('link_dial_done', all_link_ready)
            }
            that.parseDedicatedLineInfoBtnTarget.classList.toggle("btn-progress")
            that.canSubmit()
        })
    }

    buildNicTbody(nic) {
        let tbody = "<tr> <th>名称</th> <th>速率</th> <th>状态</th> </tr>"
        console.log(typeof nic);
        nic.forEach((n) => {
            console.log(typeof n);
            tbody += `<tr><td>${n['name']}</td><td>${n['speedMax']}</td><td>${(n['status'] === "1") ? "开启" : "关闭"}</td></tr>`
        })
        return tbody
    }

    checkConnectivity(event) {
        event.preventDefault()
        const that = this
        const node_id = this.nodeIdTarget.value
        const connectivityUrl = this.data.get("connectivityUrl")
        this.connectivityBtnTarget.classList.toggle("btn-progress")

        $.ajax({
            method: "POST",
            url: connectivityUrl,
            data: {
              node_id: node_id
            }
        })
        .done(function(payload) {
            let demand = that.demandTarget.value
            if($.isEmptyObject(payload)) {
                that.data.set('connectivity', false)
                that.connectivityHintTarget.innerHTML = "连接失败"
            } else {
                that.provinceTarget.value = payload.province
                that.diskCapacityTarget.value = payload.disk_capacity
                that.ramSizeTarget.value = payload.ram_size
                that.cpuCoresTarget.value = payload.cpu_cores
                that.ispTarget.value = payload.isp
                that.nicTabBodyTarget.innerHTML = that.buildNicTbody(payload.nic)
                if ($('#' + demand + '_tab')) {
                    that.data.set('connectivity', true)
                    that.connectivityHintTarget.innerHTML = "连接成功"
                } else {
                    that.data.set('connectivity', true)
                    that.connectivityHintTarget.innerHTML = "连接成功"
                }
            }
            that.connectivityBtnTarget.classList.toggle("btn-progress")
            that.canSubmit()
        }) 
    }


    routerInfoInput() {
        this.data.set('link_dial_done', ! this.emptyRouterInfo())
        this.canSubmit()
    }

    emptyRouterInfo() {
        let url = this.routerUrlTarget.value.trim()
        let account = this.routerAccountTarget.value.trim()
        let pwd = this.routerPasswordTarget.value.trim()
        return url === "" || account === "" || pwd === ""
    }

    copyUserId(event) {
        event.preventDefault()
        let userId = this.userIdTarget.value.trim()
        navigator.clipboard.writeText(userId).then(function() {
            alert('用户ID已成功复制到剪贴板');
        }, function(err) {
            alert('无法复制文本');
        });
    }
}
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "agreement", "loading", "settingBtn", 
                    "diskTable", "loginTable", "wizard1", "wizard2", 
                    "confProgress", "mountpoint", "phoneInput", "codeInput",
                    "codeBtn", "agreementContent"]

    static values = { refreshInterval: Number }

    connect() {
        var status = this.data.get("status")
        if (status == 0) {
            this.step1View()
            this.stepSelectByIndex(0)
        } else if (status == 1) {
            this.step2View()
            //轮询配置状态
            this.startSettingsStatusTimer();
            this.stepSelectByIndex(0)
        } else if (status == 2) {
            this.step3View();
            this.stepSelectByIndex(1)
        }
    }

    disconnect() {
        this.endSettingsStatusTimer()
        if (this.codeTimer) {
            clearInterval(this.codeTimer)
        }
    }

    step1View() {
        $(this.loadingTarget).hide()
        $(this.agreementTarget).show()
        $(this.settingBtnTarget).show()
        $(this.diskTableTarget).show()
        $(this.loginTableTarget).hide()
    }

    step2View() {
        $(this.loadingTarget).show()
        $(this.agreementTarget).hide()
        $(this.settingBtnTarget).hide()
        $(this.diskTableTarget).hide()
        $(this.loginTableTarget).hide()
    }

    settingBtnClick(event) {
        var that = this
        event.preventDefault()

        // 提交配置数据
        var isHaveCheck = false
        var checkData = []
        this.mountpointTargets.forEach((ch) => {
            checkData.push({
                mountpoint: ch.id,
                selected: ch.checked
            })
            if (ch.checked) {
                isHaveCheck = true
            }
        })
        
        if (!isHaveCheck) {
            swal('至少选择一个磁盘作为缓存')
            return
        }
        if (checkData.length == 0) {
            swal('未找到对应的磁盘信息')
            return
        }

        var url = '/zqb/users/conf_setttings'
        var urlData = {
            id: that.data.get("agentId"),
            disks: checkData
        }

        $.ajax({
            method: "POST",
            url: url,
            data: urlData
        }).done(function(result) {
            // console.log(result)
            if (result && result.code === 0) {
                that.step2View()
                //轮询配置状态
                that.startSettingsStatusTimer();
            } else {
                swal(result)
            }
        })

    }

    startSettingsStatusTimer() {
        var that = this
        this.refreshTimer = setInterval(() => {
            that.getSettingsStatus()
        }, 5000)
    }

    endSettingsStatusTimer() {
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer)
        }
    }

    getSettingsStatus() {
        const url = '/zqb/users/conf_progress'
        const that = this
        const urlData = {
            id: that.data.get("agentId"),
            pro: that.confProgressTarget.innerHTML
        }

        $.ajax({
            method: "GET",
            url: url,
            data: urlData
        }).done(function(result) {
            // console.log(result)
            if (result && result.code === 0) {
                that.confProgressTarget.innerHTML = result.data
                if (result.data >= 100) {
                    //配置完成
                    that.endSettingsStatusTimer()
                    that.step3View()
                }
            }
        })
    }

    step3View() {
        $(this.loadingTarget).hide()
        $(this.agreementTarget).show()
        $(this.settingBtnTarget).hide()
        $(this.diskTableTarget).hide()
        $(this.loginTableTarget).show()

        this.stepSelectByIndex(1)
    }

    stepSelectByIndex(index) {
        //class="wizard-step wizard-step-active"
        if (index == 0) {
            this.wizard1Target.className = "wizard-step wizard-step-active"
            this.wizard2Target.className = "wizard-step"
        } else if (index == 1) {
            this.wizard1Target.className = "wizard-step"
            this.wizard2Target.className = "wizard-step wizard-step-active"
        }
    }

    codeBtnClick(event) {
        var that = this
        event.preventDefault()
        var phone = this.phoneInputTarget.value
        if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))) {
            swal("手机号填写错误!");
            return
        }

        // 获取验证码
        var url = '/zqb/users/sms_code'
        var urlData = {
            phone: phone,
        }

        $.ajax({
            method: "GET",
            url: url,
            data: urlData
        }).done(function(result) {
            if (result && result.code === 0) {
                that.processCodeTime()
            } else {
                swal(result.msg)
            }
        })
    }

    processCodeTime() {
        var that = this
        this.codeBtnTarget.className = "btn btn-outline-primary disabled"
        this.codeBtnTarget.innerHTML = '获取验证码(120)'
        this.codeTimeValue = 120
        this.codeTimer = setInterval(() => {
            that.codeTimeValue--;
            that.codeBtnTarget.innerHTML = '获取验证码(' + that.codeTimeValue + ')'
            if (that.codeTimeValue == 0) {
                that.codeBtnTarget.className = "btn btn-outline-primary"
                that.codeBtnTarget.innerHTML = '获取验证码'
                clearInterval(that.codeTimer)
            }
        }, 1000)
    }

    // loginBtnClick(event) {
    //     event.preventDefault()
    //     var that = this
    //     var phone = this.phoneInputTarget.value
    //     if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))) {
    //         swal('手机号填写错误')
    //         return
    //     }

    //     var code = this.codeInputTarget.value.replace(/(^\s*)|(\s*$)/g, '')
    //     if (code == '' || code == undefined || code == null) {
    //         swal('验证码不能为空')
    //         return
    //     }

    //     swal(phone + ' & ' + code)

    //     window.location.href = "/zqb/users/" + that.data.get("agentId")
    // }

    agreementClick(event) {
        var that = this
        event.preventDefault()
        
        $(this.agreementContentTarget).modal('show')
    }

}
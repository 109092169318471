import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["withdrawalsRow", "membersRow", 
    "membersTitle", "withdrawalsTitle", "loader",
    "withdrawalsContent", "membersContent", "revenuesContent", "revenuesRow", 
    "membersCount"]

    connect() {
        this.membersClick()
    }

    membersClick(event) {
        $(this.membersContentTarget).show()
        $(this.withdrawalsContentTarget).hide()
        $(this.revenuesContentTarget).hide()

        var url = '/admin/agencies/members'
        var staffId = this.data.get("staffId")
        var urlData = {
            staff_id: staffId,
        }
        const that = this
        $(this.loaderTarget).show()

        $.ajax({
            method: "GET",
            url: url,
            data: urlData
        })
        .done(function(res) {
            // console.log(res)
            var str = ""
            var sCount = 0
            var list = res['list']
            for (var i=0,len=list.length; i<len; i++) {
                var m = list[i]
                str += "<tr><td>" + m['email'] + 
                "</td><td>" + m['qq_nickname'] +
                "</td><td>" + m['phone'] + 
                "</td><td>" + m['created_at'] + 
                "</td><td>" + m['fee_date'] + 
                "</td><td><div class='badge " + that.membersStatusClass(m)['statusClass'] + "' >" + m['status_str'] + 
                "</div></td><td>¥ " + m['last_revenue'] + 
                "</td></tr>"

                if (m['status'] == 0) {
                    sCount++;
                }
            }
            that.membersCountTarget.innerHTML = list.length
            that.membersRowTarget.innerHTML = str
            if (sCount > 0) {
                that.membersTitleTarget.innerHTML = "成员（待审核" + sCount + "）" 
            } else {
                that.membersTitleTarget.innerHTML = "成员"
            }

            if (res['withdrawal_wait_count'] > 0) {
                that.withdrawalsTitleTarget.innerHTML = "提现记录（待审核" + res['withdrawal_wait_count'] + "）" 
            } else {
                that.withdrawalsTitleTarget.innerHTML = "提现记录"
            }

            $(that.loaderTarget).hide()
        })
    }

    withdrawalsClick(event) {
        $(this.membersContentTarget).hide()
        $(this.revenuesContentTarget).hide()
        $(this.withdrawalsContentTarget).show()

        var url = '/admin/agencies/withdrawals'
        var staffId = this.data.get("staffId")
        var urlData = {
            staff_id: staffId,
        }
        const that = this
        $(this.loaderTarget).show()

        $.ajax({
            method: "GET",
            url: url,
            data: urlData
        })
        .done(function(res) {
            // console.log(res)
            var str = ""
            var sCount = 0
            var list = res['list']
            for (var i=0,len=list.length; i<len; i++) {
                var m = list[i]
                str += "<tr><td>" + m['created_at'] + 
                "</td><td>" + m['start_date'] +
                "</td><td>" + m['end_date'] + 
                "</td><td>" + (m['bank_type'] == 'personal' ? '个人' : '公司') + 
                "</td><td>¥ " + m['apply_amount'] + 
                "</td><td>¥ " + m['final_amount'] + 
                "</td><td><div class='badge " + that.withdrawalsStatusClass(m) + "' >" + m['status_str'] + 
                "</div></td><td>" + (m['remark'] ? m['remark'] : '') + 
                "</td></tr>"

                if (m['status'] == 0) {
                    sCount++;
                }
            }

            that.withdrawalsRowTarget.innerHTML = str
            if (sCount > 0) {
                that.withdrawalsTitleTarget.innerHTML = "提现记录（待审核" + sCount + "）" 
            } else {
                that.withdrawalsTitleTarget.innerHTML = "提现记录"
            }

            $(that.loaderTarget).hide()

        })
    }

    revenuesClick(event) {
        $(this.membersContentTarget).hide()
        $(this.revenuesContentTarget).show()
        $(this.withdrawalsContentTarget).hide()

        var url = '/admin/agencies/revenues_everyday'
        var staffId = this.data.get("staffId")
        var urlData = {
            staff_id: staffId,
        }
        const that = this
        $(this.loaderTarget).show()

        $.ajax({
            method: "GET",
            url: url,
            data: urlData
        })
        .done(function(res) {
            // console.log(res)
            var str = ""
            var list = res['list']
            for (var i=0,len=list.length; i<len; i++) {
                var m = list[i]
                str += "<tr><td>" + m['date'] + 
                "</td><td>¥ " + m['amount'] +
                "</td></tr>"
            }

            that.revenuesRowTarget.innerHTML = str

            $(that.loaderTarget).hide()
        })

    }

    membersStatusClass(value) {
        var classValue = 'badge-primary'
        var actionBtns = ''
        var aBtn = "<a href='#' class='btn btn-success mr-1' data-action='staff-mgr#memberCheckClick' data-user-id='" + value['user_id'] + "'><i class='fas fa-check'></i></a>" //<a href='<%= admin_staff_path(staff.id) %>' class='btn btn-primary'>详情</a>
        var bBtn = "<a href='#' class='btn btn-danger' data-action='staff-mgr#memberUncheckClick' data-user-id='" + value['user_id'] + "'><i class='fas fa-times'></i></a>"
        switch(value['status'])
        {
            case 0:
                classValue = 'badge-primary'
                actionBtns += aBtn + bBtn
                break;
            case 1:
                classValue = 'badge-success'
                actionBtns += bBtn
                break;
            case 2:
                classValue = 'badge-light'
                actionBtns = aBtn
                break;
        }

        return {
            statusClass: classValue,
            actionBtns: actionBtns
        }
    }

    withdrawalsStatusClass(value) {
        var classValue = 'badge-primary'
        switch(value['status'])
        {
            case 0:
                classValue = 'badge-primary'
                break;
            case 1:
                classValue = 'badge-warning'
                break;
            case 2:
                classValue = 'badge-danger'
                break;
            case 9:
                classValue = 'badge-success'
                break;
        }

        return classValue
    }
}
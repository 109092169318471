import { Controller } from "stimulus"

export default class extends Controller {
    static targets = []

    connect() {
        
    }

    lockStaffClick(event) {
        event.preventDefault()
        
        var msg = "【关闭入口】后，该用户将无法进入代理商相关页面";
        if (confirm(msg) == true){
            this.processLockStatus(false)
        }
    }

    unlockStaffClick(event) {
        event.preventDefault()
        
        var msg = "【打开入口】后，该用户将可以进入代理商相关页面。"; 
        if (confirm(msg) == true){
            this.processLockStatus(true)
        }
    }

    processLockStatus(status) {
        var url = '/admin/users/agency_lock'
        var userId = this.data.get("userId")
        var urlData = {
            user_id: userId,
            status: status
        }

        $.ajax({
            method: "GET",
            url: url,
            data: urlData
        })
        .done(function(res) {
            console.log(res)
            window.location.href = '/admin/users/' + userId;
        })
    }
}